import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import ContactWhatsapp from "./components/ContactWhatsapp";
import Services from "./components/Services";
import About from "./components/About";
import Team from "./components/Team";
import director from "./components/director";
import { animateScroll as scroll } from "react-scroll";
import arrowup from "./img/arrowUp.png";
import './App.css'; // Import your CSS file for styling
import Contact from './components/Contact';
import Hiring from './components/Hiring';

function App() {
    const [isBottom, setIsBottom] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.innerHeight + window.scrollY;
        const offsetHeight = document.documentElement.offsetHeight;
        setIsBottom(scrollTop >= offsetHeight);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<><Header /><Main /></>} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/hiring" element={<Hiring />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/director" element={<director />} />
                {/* Add additional routes if needed */}
            </Routes>
            <Footer />
            <ContactWhatsapp />
            <button
                onClick={() => scroll.scrollToTop(2500)}
                className={`gotop ${isBottom ? '' : 'disabled'}`}
                disabled={!isBottom}
            >
                <i className="fas fa-level-up-alt"></i>
                <img className="gotop-img" src={arrowup} alt="Go to top" />
            </button>
        </Router>
    );
}

export default App;
