import { Link } from "react-router-dom"; // Use react-router-dom for routing
import logo from '.././img/IAlogo.png';
import '../styles/navbar.css';

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");
      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");
      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });

  function openBar() {
    const bar = document.querySelector(".bar");
    bar.classList.toggle("opened");
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="row">
          <div className="logo">
            <img src={logo} alt='' className='logo-image'/>
            <Link to="/" style={{ cursor: "pointer" }}>
              INFINITE ALGOS
            </Link> 
          </div>
          <ul className="bar">
            <li>
              <Link onClick={openBar} to="/" >
                Home
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/services">
                Services
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/contact">
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/hiring">
                Hiring
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/team">
                Team
              </Link>
            </li>
            <li>
              <Link onClick={openBar} to="/directors">
                Directors
              </Link>
            </li>
          </ul>
          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
